table {
    /* table-layout:fixed; */
    width: 100% !important;
}

table.dataTable thead th, table.dataTable thead td {
    font-size: 0.620rem !important;
    overflow:hidden !important;
}

.dataTable tbody {
    font-size: 0.680rem !important;
}

.dataTables tbody tr {
    min-height: 15px !important;
}

.dataTable tfoot {
    font-size: 0.700rem !important;
}

.datatable-btn-view{
    background-color: #0162e8 !important;
    border-color: #0162e8 !important;
}

.datatable-btn-info{
    background-color: #00b9ff !important;
    border-color: #00b9ff !important;
}

.datatable-btn-danger{
    background-color: #ee335e !important;
    border-color: #ee335e !important;
}

/* .datatable-select-active {
    width: 100% !important;
    font-size: 0.750rem !important;
    background: linear-gradient(to bottom, #5da9fe, #3e73fa) !important;
    color:white !important;
    border-color: #3e73fa;
    cursor: pointer;
    padding: 0 5px 0 5px !important;
    height: 25px !important;
    text-align-last: center;
}
.datatable-select-active:focus {
    box-shadow: 0 0 0 0.25rem rgb(74 137 252 / 25%);
} */

option{
    background-color: white;
    color: black !important;
}

/* .datatable-select-active:hover {
    background-color:  #FFFFFF!important;
    color:black !important;
} */

/* .datatable-select-inactive {
    width: 100% !important;
    font-size: 0.750rem !important;
    background-color: #D87575!important;
    color:white !important;
    border-color: #D87575;
    cursor: pointer;
    padding: 0 !important;
    height: 25px !important;
    text-align-last: center;
}
.datatable-select-inactive:focus {
    box-shadow:0 0 0 0.2rem rgb(216 117 117 / 50%) ;
    border-color: #D87575 !important;
} */

/* .datatable-select-inactive:hover {
    background-color:  #FFFFFF !important;
    color:black !important;
} */

.select2-container--default .select2-selection--multiple .select2-selection__option{
    background-color:red !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #0162e8 !important;
}

.modal {
    overflow: hidden !important;
}

.select2-selection--multiple{
    overflow: scroll !important;
    max-height: 40vh !important;
}

#wallet-type-table {
    width: 100% !important;
}

.min-max >
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.min-max > input[type=number] {
    -moz-appearance: textfield;
}

.duallist-container {
    background-color: #eeeeee;
    border-radius: 3%;
}

.filter-function {
    font-size: 0.800rem !important;
}

.filter-function .filter-input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.5rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.checkbox-inline{
    position: relative;
    display: inline-block;
    padding-left: 5px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

#member_group-error{
    position: absolute;
    bottom: 30px;
    width: 200px;
}

/* .dataTables_wrapper > .dataTables_paginate > .paginate_button .page-item {
    margin-left: 1px !important;
    margin-right: 1px !important;
} */

.dataTables_paginate .pagination .page-link {
    display: inline-table !important;
}

/* .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0.2rem; }

  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem; } */

/* .filter-function > .form-control {
    height: 20px !important;
    font-size: 0.350rem !important;
    padding: 0.350rem 0.75rem !important;
} */

/* table.fixedHeader-floating{position:fixed !important;background-color:white}table.fixedHeader-floating.no-footer{border-bottom-width:0}table.fixedHeader-locked{position:absolute !important;background-color:white}@media print{table.fixedHeader-floating{display:none}} */

/* .dataTables_scrollHeadInner {
    max-width: 100% !important;
}

.dataTables_scrollHeadInner table {
    max-width: 100% !important;
} */


/* sweet alert z-index */
.swal2-container {
    z-index: 9999;
}

.timepicker-width {
    width: 250px;
}

.searchIcon{
    position: absolute;
    top: 3px;
    right: 0px;
}

.floating-image{
    width: auto !important;
    height: auto;
}

.floating-image-preview-image{
    text-align: center;
    position: relative;
}

.show-floating-image-white{
    background-color: white !important;
}

.show-floating-image-size{
    width: 500px !important;
    height: auto !important;
}

/* .page-link{
    width: 55px;
} */

.clearDesktopImage{
    position: absolute;
    top: -9px;
    right: -9px;
    color: #fff;
    background: #ff4081;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
}

.openGraphImageContainer .fileContainer .uploadPictureContainer {
	width: 60% !important;
	margin: 3%;
	padding: 10px;
	background: #55616a;
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #d0dbe4;
	position: relative;
}

.badgeBtn {
    cursor: pointer;
}

.badgeBtn:hover {
    transform: scale(1.1);
}