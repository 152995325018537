@import "variables";

// Global Buttons //
.btn-blue,
.btn-red,
.btn-orange,
.btn-white,
.btn-blue-outline,
.btn-grey-outline,
.btn-orange-outline,
.btn-secondary {
  font-size: 0.875rem;
  // line-height: 1;
  color: white;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  //   svg,
  //   img {
  //     margin-right: 0.5rem;
  //   }
  &:hover {
    color: white;
  }
}

.btn{
  transition: all ease-in-out 0.3s;
}

.btn-blue,
.btn-secondary,
.btn-warning {
  min-width: 10rem;
  width: auto;
  height: 2rem;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media  (max-width: 768px){
    min-width: unset;
  }
}

.btn-secondary  {
  background: linear-gradient(to bottom, $green-300, $green-400) !important;
  &:hover,&:focus {
    box-shadow: $green-hover-shadow;
  }
}

.btn-blue,.swal2-confirm {
  // background-color: $blue-500;
  background: linear-gradient(to bottom, $blue-400, $blue-700) !important;
  &:hover {
    box-shadow: $blue-hover-shadow;
  }
  img{
    margin-right: 0.3rem;
    width: 1.2rem;
    height: auto;
  }
}
// .btn-red {
//   // background-color: $red-400;
//   background: linear-gradient(to bottom, $red-400, $red-800);
//   &:hover{
//     box-shadow: 0 0 0 0.25rem rgba($red-400, .25);
//   }
// }
.btn-orange {
  background-color: $orange-400;
  &:hover {
    box-shadow: 0 0 0 0.25rem rgba($orange-400, 0.25);
  }
}
.btn-blue-outline {
  background-color: #fff;
  color: $blue-500;
  border-color: $blue-500;
  &:hover {
    box-shadow: $blue-hover-shadow;
    color: $blue-500;
  }
}
.btn-grey-outline {
  background-color: #fff;
  color: $gray-300;
  border-color: $gray-300;
  &:hover {
    box-shadow: 0 0 0 0.25rem rgba($gray-400, 0.25);
    color: $gray-300;
  }
}
.btn-orange-outline {
  background-color: #fff;
  color: $orange-400;
  border-color: $orange-400;
  &:hover {
    box-shadow: 0 0 0 0.25rem rgba($orange-400, 0.25);
    color: $orange-400;
  }
}
.btn-text-icon-gray,
.btn-text-icon-blue,
.btn-text-icon-red {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  fill: currentColor;
  svg {
    margin-right: 0.5rem;
    font-size: $text-20;
  }
}
.btn-icon-blue,
.btn-icon-gray,
.btn-icon-red {
  transition: 0.3s ease-in-out;
  text-decoration: none;
  svg {
    font-size: $text-20;
  }
}
.btn-icon-red {
  color: $red-400;
  svg path {
    transition: fill 0.3s ease-in-out;
  }
  &.delete:hover {
    color: $red-400;
    svg {
      fill: $red-400 !important;
    }
  }
  //   @include no-focus-hover($red-100)
}

.link-text-gray {
  display: inline-block;
  color: $gray-500;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    color: $blue-500;
  }
}

.btn-white{
  // background-color: $blue-500;
  color: #6c7ba8 !important;
  background: linear-gradient(to bottom, white, $gray-100) !important;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.16) !important;
  &:hover {
    background: linear-gradient(to bottom, $blue-400, $blue-700) !important;
    color: $white !important;
  }
  &.delete {
    &:hover {
      background-color: $red-800;
      background: $red-800 !important;
      color: $white !important;
    }
  }
}

.btn-sm {
  display: inline-flex;
  align-items: center;
  width: unset !important;
  min-width: unset;
  height: 2rem;
}

.btn-group{
  align-items: center;
  svg{
    color: $green-400;
    min-width:14px;
    height: auto;
  }
  .btn-underline {
    color: $blue-800;
    font-weight: $font-semibold;
    text-decoration: underline;
    padding:  0 0 0 .25rem;
    line-height: 1;
  }
}

.btn-back-outline{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: solid 1px #D0D8EE;
  border-radius: 0.5rem !important;
  // padding: 0.6rem 0.5rem 0.3rem 0.5rem;
  transition: 0.3s ease-in-out;
  .fe-chevron-left{
    &::before{
    color: $secondary;
    zoom:1.5;
  }
  }
  &:hover{
    box-shadow: $slate-gray-hover-shadow;
  }
}