$background: #ecf0fa;
$default-color:#031b4e;

/*Color variables*/
$primary:#00afff;
$secondary:#5f6d88;
$pink:#f10075;
$teal:#00cccc;
$purple:#673ab7;
$success:#40AD48;
$warning:#fbbc0b;
$danger:#ff6868;
$info:#00b9ff;
$orange:#fd7e14;
$dark:#3b4863;
$indigo:#ac50bb;
$white:#fff;
$black:#000;

$blue-200: #aad4fc;
$blue-300: #62b2ff;
$blue-400: #5da9fe; // Secondary
// $blue-500: #559cfd; // Primary
$blue-500: #4a89fc; 
$blue-600: #437dfb; 
$blue-700: #3e73fa;
$blue-800: #2861E1;

$red-100:#FEF6F6;
$red-400: #ff6868;
$red-800:#EA4E3D;

$green-100:#F6FDF7;
$green-300:#08D606;
$green-400: #04BE02;

$orange-100: 	#fff2db;
$orange-300: #f7c28f;
$orange-400: 	#ff9900;

/*gray variables*/
// $gray-100:#ecf0fa;
// $gray-200:#dde2ef;
// $gray-300:#d0d7e8;
$gray-100: #F7F7F7;
$gray-200: #E1E1E1;
$gray-300: #B1B1B1;
$gray-400:#b9c2d8;
$gray-500:#949eb7;
// $gray-600:#737f9e;
$gray-600: #5d6981;
$gray-700:#4d5875;
$gray-800:#364261;
$gray-900:#242f48;




/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow: -8px 12px 18px 0 #dadee8;

$dark-theme:#1f2940;

$border:#e3e8f7;

//Font-weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

//Font-size
$text-9: 0.5625rem;
$text-10: 0.63rem;
$text-12: 0.75rem;
$text-14: 0.875rem;
$text-16: 1rem;
$text-18: 1.125rem;
$text-20: 1.25rem;
$text-24: 1.5rem;
$text-30: 1.875rem;
$text-36: 2.25rem;
$text-48: 3rem;
$text-60: 3.75rem;
$text-72: 4.5rem;
$text-96: 6rem;
$text-128: 8rem;

//Line-height
$leading-tight : 1.25;
$leading-snug : 1.375;
$leading-normal : 1.5;
$leading-relaxed : 1.625;
$leading-loose: 2;

.bg-gray-200 {
  background-color: $gray-200;
}

//Shodow
//Card Shadow
$slate-gray-card-shadow: 0 4px 12px 0 rgba($gray-500, 0.25);
//White Buttons/Objects
$slate-gray-shadow: 0 3px 5px rgba($gray-500, 0.25);
//Blue Buttons/Objects
$blue-shadow: 0 3px 5px rgba($blue-500, 0.25);

//Hover Shadow
//White Hover Buttons/Objects
$slate-gray-hover-shadow: 0 0 0 0.25rem rgba($gray-500, 0.25);
//Blue Hover Buttons/Objects
$blue-hover-shadow: 0 0 0 0.25rem rgba($blue-500, 0.25);
//Red Hover Buttons/Objects
$red-hover-shadow: 0 0 0 0.25rem rgba($red-400, 0.25);
//Green Hover Buttons/Objects
$green-hover-shadow: 0 0 0 0.25rem rgba($green-400, 0.25);


@keyframes drawOut {
  from{
    width: 0;
  }
  to{
    width: 100%;
  }
}
