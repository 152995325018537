@import "variables";

//Login page
.login__background {
  background-color: $gray-100;

  img {
    width: 200px !important;
    padding: 2rem;
  }
  // background-image: linear-gradient(141deg,#9fb8ad 0%,#1fc8db 51%,#2cb5e8 75%);
}
.login__container {
  box-shadow: rgba(34, 42, 54, 0.12) 0px 5px 19px 5px;
}

.card-sigin {
  padding: 2rem;
}

.login__input {
  border-bottom: 1px solid $gray-500;
  svg {
    width: 20px;
    height: 20px;
    color: $gray-500;
  }
}

.main-signup-header {
  h2 {
    color: $primary !important;
  }
  img {
    width: 300px;
  }
  .btn {
    padding: 6px 12px !important;
    background-color: $primary !important;
  }
}

// Modal Select Language
.modal-select-country {
  height: 100%;
  .modal-content {
    background-color: white;
    border: 0;
    .modal-header {
      border-bottom: 0;
      background-color: white;
      justify-content: center;
      h5 {
        color: black;
        margin-bottom: 0;
      }
      svg {
        cursor: pointer;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .col-12 {
        cursor: pointer;
        &:hover {
          // background-color: $primary;
          // border-radius: 5px;
          // transition: all 0.5s ease-in-out;
          h6 {
            color: $primary;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      h6 {
        color: $gray-600;
      }
      .modal-country {
        display: flex;
        align-items: center;
        .modal-country-flag {
          border-radius: 50%;
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          width: 45px;
          height: 45px;
        }
        .modal-country-languages {
          display: flex;
          .modal-country-language {
            position: relative;
            padding: 0 15px;
            &:not(:first-child) {
              border-left: 1px solid $gray-300;
            }
            .modal-country-language-link {
              color: $gray-300;
              cursor: pointer;
              line-height: 2;
              &:hover {
                color: $blue-500;
                transition: all 0.15s ease-in-out;
              }
            }
            .modal-country-language-link.active {
              color: $blue-500;
            }
          }
        }
      }
    }
  }
}

.ReactModal__Overlay {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

//change p css in email content display in message board(show email content)
.email-content {
  p {
    margin-bottom: 0 !important;
  }
}

//language
.language__selected:hover {
  color: $primary;
}

//btn arrow
.btn-text-icon-gray,
.btn-text-icon-blue {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  svg {
    margin-right: 0.5rem;
    font-size: 1.25rem;
    fill: currentColor;
  }
}
.btn-text-icon-gray {
  color: $gray-300;
  //   @include no-focus-hover($gray-100)
}

.show-btn {
  margin-top: 1rem;
  display: none;
}

.filter-function.close {
  opacity: unset !important;
  float: unset !important;
  font-weight: unset !important;
}

.success-btn {
  background-color: #fff;
  color: $green-400;
  border: 1px solid;
}

.success-btn:hover {
  color: $green-400;
  box-shadow: $green-hover-shadow;
}

.danger-btn {
  background-color: #fff;
  color: $danger;
  border: 1px solid;
}

.danger-btn:hover {
  color: $danger;
  box-shadow: $red-hover-shadow;
}

.third__btn {
  border: 1px solid $primary;
  color: $primary;
  background-color: white;
  border-radius: 3px !important;
  &:hover {
    box-shadow: 0 0 0 0.25rem rgba(0, 175, 255, 0.25);
    color: $primary;
  }
}

.hover__btn:hover {
  box-shadow: 0 0 0 0.25rem rgba(0, 175, 255, 0.25) !important;
  background-color: $primary !important;
}

//react table btn-info
.react-bootstrap-table {
  min-width: 768px !important;
  table {
    min-width: max-content;
    th {
      word-wrap: normal;
    }
  }
  .btn-rebate {
    background: white;
    border: 1px solid $warning;
    &:active {
      background: white;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(251, 188, 11, 0.5) !important;
      }
    }
    svg {
      color: $warning;
    }
  }

  .btn-info {
    background: white;
    border: 1px solid $primary;
    &:active {
      background-color: white !important;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 175, 255, 0.5) !important;
      }
    }
    svg {
      color: $primary;
    }
  }

  .btn-danger {
    background: white;
    border: 1px solid $danger;
    &:active {
      background-color: white !important;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 104, 104, 0.5) !important;
      }
    }
    svg {
      color: $danger;
    }
  }
}
//
//deposit table
.react-bootstrap-table {
  .deposit__table,
  .promotion__table,
  .member_transaction {
    min-width: 1000px;
  }
  textarea {
    border-color: #e1e5ef !important;
  }
}

.col-md-3.col-6,
.col-md-4.col-6 {
  .form-group {
    margin-bottom: 0px !important;
  }
}

.filter-function {
  .col.mt-auto {
    div {
      margin-top: 1rem;
    }
  }
}

// 1 row 4 coloumns
.filter-wrapper-1 {
  .col-md-3.col-6 {
    &:nth-child(n + 5) {
      margin-top: 1rem;
    }
    @media (max-width: 768px) {
      &:nth-child(n + 3) {
        margin-top: 1rem;
      }
    }
  }
}

// 1 row 3 columns
.filter-wrapper-2 {
  .col-md-4.col-6 {
    &:nth-child(n + 5) {
      margin-top: 1rem;
    }
    @media (max-width: 768px) {
      &:nth-child(n + 3) {
        margin-top: 1rem;
      }
    }
  }
}

.filter-wrapper-3 {
  .col-md-4.col-6 {
    &:nth-child(n + 4) {
      margin-top: 1rem;
    }
    @media (max-width: 768px) {
      &:nth-child(n + 3) {
        margin-top: 1rem;
      }
    }
  }
}

.available_balance {
  color: #40ad48;
}

.select__show {
  padding: 2px 10px;
  border-radius: 5px;
  border-color: $gray-200;
  appearance: none;
}

//select day dropdown list
.day__select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  padding: 5px 10px;
  // background-color: $white;
  border-color: $primary;
  color: $white;
  font-weight: 500;
  appearance: auto;
  border-radius: 3px;
  // width: 160px;
}

.dropdown-menu {
  margin-top: 5px !important;
}

//react-select-async-paginate
.css-yk16xz-control {
  border-color: #e1e5ef !important;
  border-radius: 3px !important;
  .css-g1d714-ValueContainer {
    padding: 5px 8px;
  }
  &:focus {
    border-color: #b3c6ff !important;
  }
}

.css-1pahdxg-control {
  border-color: #b3c6ff !important;
  border-radius: 3px !important;
  box-shadow: none !important;
}

//bank create date Picker
.bank__datePicker {
  .wrapperDatePicker {
    width: unset !important;
  }
}

//profile css
.profile__user {
  margin: 12px 0px;
  border-radius: 5px;
  display: flex;
  background: linear-gradient(180deg, #fff, #f1f2f3);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: space-around;
  .profile__user__content {
    // color: white;
    color: black;
    display: flex;
    flex-direction: column;
    word-break: break-all;
    align-items: center;
    text-align: center;
    h6 {
      color: #031b4e;
      font-weight: bold !important;
    }
    h3 {
      font-size: 1rem;
    }
  }
}

//upload img btn
.fileUploader {
  .fileContainer {
    padding: 1rem !important;
    margin: 0px !important;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    box-shadow: unset;
    border: 1px solid $border;
    border-radius: 0;
    .uploadIcon {
      display: none;
    }

    p {
      font-size: 10px;
      text-align: center;
    }
    .chooseFileButton {
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 3px !important;
    }
    .uploadPictureContainer {
      width: 300px !important;
      height: auto !important;
      padding: 0px !important;
      margin: 0px !important;
      margin-bottom: 16px !important;
      margin-top: 6px !important;
      object-fit: contain;
    }
  }
}

.detail__request {
  background-color: transparent;
  color: #ee335e;
  border: 0.5px solid #ee335e;
  box-shadow: rgba(238, 51, 94, 0.2) 0px 2px 8px 0px;

  ul {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 968px) {
  .profile__user__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 720px) {
  .pagination__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .filter-function.open {
    height: 100%;
    transition: all 0.2s ease;
  }

  .filter-function.close {
    overflow-y: hidden;
    height: 0px;
    transition: all 0.2s ease;
  }

  .show-btn {
    display: flex;
    justify-content: center;
  }

  .show-btn svg {
    color: #00afff;
    width: 20px;
    height: 20px;
  }
}

.sidebar-user-img {
  width: 65px !important;
  height: 65px !important;
  background: radial-gradient(
    circle,
    rgba(34, 54, 195, 1) 0%,
    rgba(111, 204, 249, 1) 100%
  );
}

svg {
  g {
    circle {
      display: none;
    }
  }
}

.copy-input {
  position: relative;
  input {
    // background-color: $gray-100 !important;
    // color: $blue-500;
    // font-weight: $font-medium;
    padding-right: 2rem;
  }
  img,
  svg {
    position: absolute;
    top: 12px;
    right: 14px;
    z-index: 2;
    cursor: pointer;
    &:hover {
      filter: brightness(0.5);
    }
  }
}

.dashboard-card {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0px;
  }
  .card-icon-container {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: white;
      width: 20px;
      height: 20px;
    }
  }
  .card-content {
    margin-left: 1rem;
    p {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: bold;
      color: $gray-400;
    }
    h4 {
      font-size: 14px;
    }
  }
}

@media (min-width: 1200px) {
  .master-container {
    min-width: 1400px;
  }
}

.input-side-btn {
  width: 46px !important;
  min-width: unset !important;
  height: 40px !important;
  padding: 0;
  background: unset;
  color: $gray-500;
  position: absolute;
  top: 0;
  right: -0.75rem;
  &:hover {
    box-shadow: unset;
    color: $primary;
  }
}

.table-footer {
  div {
    p {
      color: $blue-500;
      margin-bottom: 0;
    }
    .sub-total {
      color: black;
      font-weight: normal;
    }
  }
}

.dashboard-card-small {
  height: 90%;
  .card-body {
    box-shadow: unset !important;
  }
}

//new updated css

//dropdown select styling
.select-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, $gray-200, $white);
  border: 1px solid #e1e5ef;
  border-radius: 0.2rem;
  select {
    &.filter-input,
    &.datatable-select {
      position: relative;
      appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
    &.datatable-select {
      width: 100%;
      text-align: center;
      &:focus-visible {
        outline: none;
      }
    }
    &.form-control:valid,
    &.form-control:invalid {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .updown-icon {
    width: 15px;
    height: 15px;
    padding-right: 0.5rem;
  }
  &:has(.datatable-select-active),
  &:has(.datatable-select-inactive) {
      width: 100%;
      height: auto;
      min-height: 2rem;
      padding: 0;
      .datatable-select {
        color: $white;
      }
  }
  &:has(.datatable-select-active){
    background: linear-gradient(to bottom, $blue-400, $blue-700) !important;
  }
  &:has(.datatable-select-inactive){
    background: linear-gradient(to bottom, $red-400, $red-800) !important;
  }
  &.bg-white {
    background: $white;
  }
  // &.datatable-select-active,
  // &.datatable-select-inactive {
  //   width: 100%;
  //   height: auto;
  //   min-height: 2rem;
  //   padding: 0;
  //   .datatable-select {
  //     color: $white;
  //   }
  // }
  // &.datatable-select-active {
  //   background: linear-gradient(to bottom, $blue-400, $blue-700) !important;
  // }
  // &.datatable-select-inactive {
  //   background: linear-gradient(to bottom, $red-400, $red-800) !important;
  // }
}

.was-validated {
  .select-input {
    &:has(.form-control:valid) {
      border-color: #22c03c;
    }
    &:has(.form-control:invalid) {
      border-color: #ee335e;
    }
  }
}

//checkbox css
.yl-checkbox-container {
  input[type="checkbox"] + label {
    display: inline-block;
    margin: 0;
    cursor: pointer;
    line-height: 1;
    &.margin-r {
      &::before {
        margin-right: 0.25rem;
      }
    }
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    content: "\2714";
    border: 1px solid #000;
    border-radius: 0.2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $text-10;
    width: 14px;
    height: 14px;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
  }

  input[type="checkbox"] + label:active:before {
    transform: scale(0);
  }

  input[type="checkbox"]:checked + label:before {
    background-color: $warning;
    border-color: $warning;
    color: $white;
  }

  input[type="checkbox"]:disabled + label:before {
    transform: scale(1);
    border-color: $gray-300;
  }

  input[type="checkbox"]:checked:disabled + label:before {
    transform: scale(1);
    background-color: $orange-300;
    border-color: $orange-300;
  }
}

//tab
.main-nav-line {
  margin: 3px;
  .nav-link {
    background: transparent !important;
    border: 0 !important;
    &.active {
      position: relative;
      box-shadow: none !important;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background: $blue-600;
        bottom: 0;
        left: 0;
        border-radius: 1rem;
        animation: drawOut 0.25s;
      }
    }
  }
}

//show member name
.member-name {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-left: 1rem;
  color: $gray-600;
  svg {
    color: $gray-600 !important;
    margin-right: 0.5rem;
  }
}

//table image
.tb-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

//accordion
.global_accordion {
  padding: 0 0 1rem 0;
  .accordion__item {
    border-top: 0 !important;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    .accordion__heading {
      .accordion__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all ease-in-out 0.3s;
        background: $white;
        border-radius: 0.5rem;
        border: 1px solid $gray-200;
        font-weight: $font-medium;
        text-transform: uppercase;
        &::before {
          display: none;
        }
        &::after {
          display: inline-block;
          content: "";
          height: 10px;
          width: 10px;
          margin-right: 12px;
          border-bottom: 2px solid currentColor;
          border-right: 2px solid currentColor;
          transform: rotate(-45deg);
          transition: all ease-in-out 0.3s;
        }
      }
      .accordion__button[aria-expanded="true"] {
        border: 0;
        background: rgba($blue-300, 0.1);
        color: $blue-700;
        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
}

//preview image css
.preview-image {
  position: relative;
  .clearImage {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    border-radius: 5rem;
    background: $red-800;
    border: 0;
    color: white;
    z-index: 1;
  }
  .banner {
    width: 100%;
    height: auto;
    margin: 1rem 0;
    padding: 0;
  }
}

//video dropzon
.video-drop {
  p {
    cursor: pointer;
  }
  .with-bg {
    width: 100%;
    height: 107px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba($blue-200, 0.2);
  }
}
//end

//react-time-picker
.react-time-picker {
  display: flex;
  .react-time-picker__wrapper {
    border: 0;
  }
}
