.tree, .tree ul {
    margin:0;
    padding:0;
    list-style:none
}
.tree ul {
    margin-left:1em;
    position:relative
}
.tree ul ul {
    margin-left:.5em
}
.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid #e3e3e3;	
}
.tree li {
    margin:0;    
    line-height:2em;
    color:#56546d;
    position:relative
}
.tree ul li {
	padding:0 1em;
}
.tree li i{
    margin-right: 5px;
    font-weight: bold;
	color: #0162e8;
}
.tree ul li:before {
    content:"";
    display:block;
    width:10px;
    height:0;
    border-top:1px solid #e3e3e3;
    margin-top:-1px;
    position:absolute;
    top:1em;
    left:0;	
}
.tree ul li:last-child:before {
    background:#fff;
    height:auto;
    top:1em;
    bottom:0;	
}
.indicator {
    margin-right:5px;
	color: #4b10a4;
}
.tree li a {
    text-decoration: none;
    color:#29273c;
}
.tree li button, .tree li button:active, .tree li button:focus {
    text-decoration: none;
    color:#29273c;
    border:none;
    background:transparent;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    outline: 0;
}